
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { CacheTagPrefix, useCache } from '@zaprooecommerce/vsf-cache'
import { getCoverImage, getPrice, getProductSku } from '~/modules/catalog/product/getters/productGetters'
import { useCart } from '~/modules/checkout/composables/useCart'
import { useWishlist } from '~/modules/wishlist/composables/useWishlist'
import { GtagItemListActions, GtagProductAnalyticsData, sendGtagItemListEvent } from '~/modules/aatrium-gtm'
import {
  getImageByPosition,
  getLoyaltyPrice,
  isAddableToWishlist,
  isGiftCardProduct
} from '~/modules/catalog/aatrium/getters/productGetters'

import ProductImage from '~/components/molecules/ProductImage.vue'
import AddToCart from '~/components/molecules/AddToCart.vue'
import Heading from '~/components/atoms/Heading.vue'
import AddToWishList from '~/components/molecules/AddToWishList.vue'
import ProductBadges from '~/components/ProductBadges/ProductBadges.vue'
import ProductPrice from '~/modules/catalog/aatrium/components/ProductPrice.vue'
import BaseLink from '~/components/atoms/BaseLink.vue'

import { preparedGtagItems } from '~/modules/aatrium-gtm/helpers'
import { useProductAnalyticsStore } from '~/modules/catalog/aatrium/product/store/useProductAnalyticsStore'
import ProductCardStatuses from '~/components/molecules/ProductCardStatuses.vue'
import CheapestMonthlyInstallmentPayment from '~/components/molecules/CheapestMonthlyInstallmentPayment.vue'
import { ProductShort } from '~/types/product'
import { createProductPath } from '~/modules/product-correct-url/helpers'
import { getSlug } from '~/modules/checkout/getters/cartGetters'

export default defineComponent({
  name: 'ProductCard',
  components: {
    CheapestMonthlyInstallmentPayment,
    ProductCardStatuses,
    AddToCart,
    ProductImage,
    ProductPrice,
    ProductBadges,
    AddToWishList,
    Heading,
    BaseLink
  },
  props: {
    product: {
      type: Object as PropType<ProductShort>,
      default: null
    },
    link: {
      type: [String, Object],
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    headingLevel: {
      type: Number,
      default: 3
    },
    analyticsData: {
      type: Object as PropType<GtagProductAnalyticsData>,
      required: false,
      default: null
    },
    hoverOnCard: {
      type: Boolean,
      required: false,
      default: false
    },
    imagePreload: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const product = computed(() => props.product)
    const { addItem } = useCart()
    const { isInWishlist, removeItem } = useWishlist()
    const { addTags } = useCache()
    const { setProductId } = useProductAnalyticsStore()

    const isGiftCard = computed<boolean>(() => isGiftCardProduct(product.value))
    const productInWishlist = computed(() => isInWishlist({ product: product.value }))
    const basicPrices = computed(() => getPrice(product.value))
    const loyaltyPrice = computed(() => {
      const loyaltyPrice = getLoyaltyPrice(product.value)
      return loyaltyPrice && basicPrices.value.regular > loyaltyPrice && !basicPrices.value.special ? loyaltyPrice : 0
    })
    const productLink = computed(() => props.link || createProductPath(getProductSku(product.value), getSlug({ product: product.value })))

    const productPrice = computed(() => ({
      ...basicPrices.value,
      regular: isGiftCard.value ? 10 : basicPrices.value.regular,
      special: isGiftCard.value ? undefined : basicPrices.value.special || loyaltyPrice.value || undefined
    }))

    const mainImage = computed(() => product.value.image.url)
    const hoverImage = computed(() => getImageByPosition(product.value, 2))

    async function addProductToCart (): Promise<void> {
      const typename = product.value?.__typename

      if (typename === 'SimpleProduct') {
        await addItem({ product: product.value as any, quantity: 1 }, props.analyticsData)

        if (productInWishlist.value) {
          await removeItem({ product: product.value as any })
        }
      }
    }

    function clickHandler (e: Event): void {
      emit('click')
      e.stopImmediatePropagation()
      if (props.analyticsData) {
        sendGtagItemListEvent(GtagItemListActions.SelectItem, {
          items: preparedGtagItems([{ product: props.product, index: props.analyticsData.index }]),
          item_list_name: props.analyticsData.list
        })
        setProductId(props.analyticsData?.index ?? 1)
      }
    }

    if (process.server && product.value?.uid) {
      addTags([{ prefix: CacheTagPrefix.Product, value: product.value?.uid }])
    }

    return {
      productPrice,
      productInWishlist,
      addProductToCart,
      getCoverImage,
      clickHandler,
      productLink,
      hoverImage,
      mainImage,
      isAddableToWishlist,
      isGiftCard,
      loyaltyPrice
    }
  }
})
