<template>
  <component
    :is="linkComponentTag"
    v-focus
    v-bind="urlTag"
    class="link"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>
<script>
import { focus } from "@storefront-ui/vue/src/utilities/directives";

export default {
  name: "BaseLink",
  directives: { focus },
  props: {
    link: {
      type: [String, Object],
      required: true,
    },
  },
  computed: {
    isExternal() {
      return (
        typeof this.link === "string" && this.link.search(/(^\/|^#)/g) === -1
      );
    },
    isNativeLinkTag() {
      return this.isExternal || !this.$router;
    },
    urlTag() {
      return this.isNativeLinkTag
        ? { href: this.link }
        : { to: this.link || "" };
    },
    linkComponentTag() {
      const routerLink = this.$nuxt ? "nuxt-link" : "router-link";
      return this.isNativeLinkTag ? "a" : routerLink;
    },
  },
};
</script>
<style lang="scss">
.link {
  color: var(--link-color, var(--c-text));

  text-decoration: var(--link-text-decoration, underline);
  &:visited {
    --link-color: var(--c-link);
  }
  &:hover {
    --link-color: var(--c-primary);
  }
  &:active {
    --link-color: var(--c-link);
  }
}
</style>
